<script>
import config from "../config";
import {replaceByDefault } from "../Helper/helper";
export default {
    data() {
        return {
            config : config,
            replaceByDefault: replaceByDefault
        };
    },
}
</script>
<template>
    <div class="navbar-brand-box navbar-brand-m" v-if="$auth.user.vendor_role == '0'">
        <a href="#" class="logo logo-dark" v-if="$auth.setting">
          <span class="logo-sm">
            <img :src="config.foodicon+$auth.setting.flat_icon" @error="replaceByDefault" alt  height="22"/>
          </span>
          <span class="logo-lg">
            <img :src="config.foodicon+$auth.setting.app_logo" alt  height="17"/>
          </span>
        </a>

        <a href="#" class="logo logo-light" v-if="$auth.setting">
          <span class="logo-sm">
            <img :src="config.foodicon+$auth.setting.flat_icon" alt height="22"/>
          </span>
          <span class="logo-lg">
            <img :src="config.foodicon+$auth.setting.app_logo" @error="$event.target.src=config.no_image" alt height="25"/>
          </span>
        </a>
      </div>
      <div class="navbar-brand-box navbar-brand-m" v-else>
        <a href="#" class="logo logo-dark" v-if="$auth.setting">
          <span class="logo-sm">
            <img :src="config.foodicon+$auth.setting.flat_icon" @error="replaceByDefault" alt  height="22"/>
          </span>
          <span class="logo-lg">
            <img :src="config.foodicon+$auth.setting.app_logo" alt  height="17"/>
          </span>
        </a>

        <a href="#" class="logo logo-light" v-if="$auth.setting">
          <span class="logo-sm">
            <img :src="config.foodicon+$auth.setting.flat_icon" alt height="22"/>
          </span>
          <span class="logo-lg">
            <img :src="config.foodicon+$auth.setting.app_logo" @error="$event.target.src=config.no_image" alt height="25"/>
          </span>
        </a>
      </div>
</template>