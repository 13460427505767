<script>
import config from "../../config";
import { commonService, vendorService } from "../../services";
import { clientService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import { required, email, sameAs, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import firebase from "firebase";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { Multiselect, clientService, VueGoogleAutocomplete },
  data() {
    return {
      showModel: true,
      socketData: null,
      config: config,
      isLoading: false,
      isContactVerify: false,
      percentage: 0,
      error: "",
      typeform: {
        app_name: "",
        vendor_name: "",
        vendor_number: "",
        email: "",
        password: "",
        confirm_password: "",
        country_code: "",
        timezone: "UTC",
        currency: "&#36;",
        currency_short_name: "USD",
        website_url: "",
        is_quick_signup: true,
      },
      currencies: [],
      currency: [],
      timezones: [],
      timezone: [],
      countrycodes: [],
      login: {},
      countries: [],
      logo: "",
      frontUrl: "",
      default_appname: "",
      termscondition: "",
      termscondition_error: false,
      isSubmitDisable: false,
      product_type_list: [],
      business_type_list: [],
      gif_folder_path:
        process.env.MIX_APP_ENV == "production" ||
        process.env.MIX_APP_ENV == "development"
          ? "public/images/gif/spinner.gif"
          : "images/gif/spinner.gif",
    };
  },
  watch: {
    // Watch for changes in socketData
    socketData(newValue, oldValue) {
      this.percentage = this.socketData.process_status;
      if (this.socketData.process_status == 100) {
        localStorage.removeItem("quick_signup_vendor");
        this.loginVendor();
      }
    },
  },
  mounted() {
    let data = localStorage.getItem("quick_signup_vendor");
    this.login = JSON.parse(data);
    this.sockets.subscribe("vendor_setup_" + this.login.vendor_id, (data) => {
      this.socketData = data;
    });

    this.getProductBusinessTypes();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    // });
    this.getLanguage();
  },
  beforeDestroy() {
    this.sockets.unsubscribe("vendor_setup_" + this.login.vendor_id);
  },
  beforeCreate() {
    // if (localStorage.getItem("quick_signup_vendor")) {
    let data = localStorage.getItem("quick_signup_vendor");
    this.login = JSON.parse(data);
    this.sockets.subscribe("vendor_setup_" + this.login.vendor_id, (data) => {
      this.socketData = data;
    });
    // }
  },
  methods: {
    getProductBusinessTypes() {
      clientService.getProductBusinessTypes().then((response) => {
        if (response.data.result.product_type_list) {
          this.product_type_list = response.data.result.product_type_list;
        }
        if (response.data.result.business_type_list) {
          this.business_type_list = response.data.result.business_type_list;
        }
      });
      this.getLanguage();
    },
    nameWithLang({ code, name }) {
      return `(+${code}) ${name}`;
    },
    asyncFind(query) {
      vendorService.getLanguage(query).then((response) => {
        this.countrycodes = response.data.result.country_code;
      });
    },
    clearAll() {
      this.countries = [];
    },
    nameWithCurrency({ symbol, name }) {
      return `${symbol} (${name})`;
    },
    asyncCurrencyFind(query) {
      vendorService.getLanguage(query).then((response) => {
        this.currencies = response.data.result.currency;
      });
    },
    clearAll() {
      this.currency = [];
    },
    asyncZoneFind(query) {
      vendorService.getLanguage(query).then((response) => {
        this.timezones = response.data.result.timezone;
      });
    },
    clearAll() {
      this.timezone = [];
    },
    getLanguage() {
      vendorService.getLanguage().then((response) => {
        this.currencies = response.data.result.currency;
        this.timezones = response.data.result.timezone;
        this.countrycodes = response.data.result.country_code;
        if (
          response.data.result.logo &&
          response.data.result.logo != "" &&
          response.data.result.logo != null &&
          response.data.result.flat_icon_path &&
          response.data.result.flat_icon_path != "" &&
          response.data.result.flat_icon_path != null
        ) {
          this.logo =
            response.data.result.flat_icon_path + "/" + response.data.result.logo;
          // document
          //   .querySelector('[type="image/x-icon"]')
          //   .setAttribute("href", this.logo);
        }
        if (response.data.result.app_name && response.data.result.app_name != "") {
          this.default_appname = response.data.result.app_name;
          document.querySelector("head title").textContent =
            response.data.result.app_name;
        }
      });
    },
    termsconditionfun(term) {
      this.termscondition_error = false;
      if (term != "accepted") {
        this.termscondition_error = true;
      }
    },
    loginVendor() {
      let payload = {
        email: this.login.email,
        password: this.login.password,
        login_type: "vendor",
      };
      this.$auth.login(payload, (type) => {
        this.isSubmitDisable = false;
        document.querySelector("title").innerHTML = this.$auth.setting.app_name;
        let fvicon = document.querySelector('[type="image/x-icon"]');
        if (fvicon) {
          //   fvicon.setAttribute("href", this.$auth.setting.flat_icon);
        }
        this.$router.push({ name: type }).then(() => {
          window.location.reload();
        });
      });
    },
    onCurrencyChange() {
      if (this.typeform.currency) {
        var curr = this.currencies.find(
          (elem) => elem.symbol == this.typeform.currency.symbol
        );
        if (curr) {
          this.typeform.currency_short_name =
            curr.short_name != null ? curr.short_name : "";
        }
      } else {
        this.typeform.currency_short_name = "";
      }
    },
    removeSpecialChars(str) {
      return str
        .replace(/(?![A-Za-z])./g, "")
        .replace(/\s+/g, " ")
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2");
    },
    updateFrontLink(e) {
      this.typeform.website_url = "";
      this.frontUrl = "";
      if (e.target.value && e.target.value != "") {
        this.typeform.website_url = this.removeSpecialChars(e.target.value).toLowerCase();
        this.frontUrl = (this.typeform.website_url != ""
          ? "https://" + this.typeform.website_url + config.orderWebDefaultUrl
          : ""
        ).toLowerCase();
      }
    },
  },
};
</script>

<template>
  <b-modal
    :id="'modal-quick-signup'"
    lazy
    ok-title="Cancel"
    ok-only
    ok-variant="secondary"
    size="lg"
    v-model="showModel"
    :no-close-on-esc="percentage < 100"
    :no-close-on-backdrop="percentage < 100"
    hide-header
    hide-footer
    dialog-class="modal-dialog-centered"
  >
    <div class="p-5 m-5">
      <div class="d-flex justify-content-center mb-5">
        <img :src="config.appurl + gif_folder_path" alt="Loading" class="loading-gif" />
      </div>
      <div class="d-flex justify-content-center mb-5">
        <h3>Setting up your Account ...</h3>
      </div>
      <div class="progress-container" :data-percentage="percentage">
        <div class="progress" :style="{ width: percentage + '%' }"></div>
        <!-- <div class="percentage">{{ percentage }}%</div> -->
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.progress-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1); */
}

.progress-container,
.progress {
  background-color: #eee;
  border-radius: 5px;
  position: relative;
  height: 4px;
  width: 500px;
}

.progress {
  background-color: #556ee6;
  transition: width 0.4s linear;
}

.percentage {
  background-color: #556ee6;
  border-radius: 5px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  padding: 4px;
  position: absolute;
  top: 20px;
  left: 0;
  transform: translateX(-50%);
  width: 40px;
  text-align: center;
  transition: left 0.4s linear;
}

.percentage::after {
  background-color: #556ee6;
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  height: 10px;
  width: 10px;
  z-index: -1;
}
.loading-gif {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
}
</style>
