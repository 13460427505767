import { multipart_headers } from "../Helper/helper";
import { routes } from "../config/api_routes";

export const commonService = {
    removeTmpImage: (file) =>
        window.axios.post(routes.removeTmpImageApi, { file }),
    signup: (formData) => window.axios.post(routes.signupApi, formData),
    checkClientNumber: (formData) =>
        window.axios.post(routes.checkClientNumber, formData),
    quickSignup: (formData) =>
        window.axios.post(routes.quickSignupApi, formData),
    checkVendorEmail: (formData) =>
        window.axios.post(routes.checkVendorEmailApi, formData),
    checkVendorNumber: (formData) =>
        window.axios.post(routes.checkVendorNumberApi, formData),
    quickSignupEmailOtpSend: (formData) =>
        window.axios.post(routes.quickSignupEmailOtpSendApi, formData),
    quickSignupEmailOtpReSend: (formData) =>
        window.axios.post(routes.quickSignupEmailOtpReSendApi, formData),
    quickSignupEmailOtpVerify: (formData) =>
        window.axios.post(routes.quickSignupEmailOtpVerifyApi, formData),
    checkBusinessName: (formData) =>
        window.axios.post(routes.checkBusinessNameApi, formData),
};
