<script>
import config from "../config";
import maintenanceTextModal from "./maintenance-text-modal.vue";

export default {
  components: {
    maintenanceTextModal,
  },
    data(){
        return {
              config,
              maintenanceTextModal:false
        };
    },
    methods:{
      loadContent(){
        var tripetto = TripettoServices.init({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiTmJza3FDbVRRalVPamhRcnhISmhwc0hEMDg1d2NPNlQzbEtHUlovZUNmbz0iLCJkZWZpbml0aW9uIjoiWnBpNjJscjdhQ1NXTHhYalJxSlFyVk1DdEhPdEMwTjkxR3hLczhieEFpaz0iLCJ0eXBlIjoiY29sbGVjdCJ9.QlLyRV5uZFPd8Qn1IJmpDSfEX–kvpAtworxi3HxPaM" });
        TripettoAutoscroll.run({
        element: document.getElementById("tripetto"),
        definition: tripetto.definition,
        styles: tripetto.styles,
        l10n: tripetto.l10n,
        locale: tripetto.locale,
        translations: tripetto.translations,
        attachments: tripetto.attachments,
        onSubmit: tripetto.onSubmit
        });
      },
      maintenanceTextModalClose(e){
        this.maintenanceTextModal = false
      }
    }
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{trans.get('__JSON__.Copyright')}} © {{ new Date().getFullYear() }} {{ trans.get('Deonde') }} {{trans.get('__JSON__.All rights reserved')}}.</div>
        <div v-if="!$auth.partner_login">
          <div class="col-sm-6 text-right d-none">
              <b-button variant="primary" pill v-b-modal.modal-1 >Demo</b-button>
              <b-modal id="modal-1" size="lg" title="Demo" @shown="loadContent">
                <div id="tripetto"></div>
              </b-modal>
          </div>
        </div>
        <!-- <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">Design & Develop by {{config.appname}}</div>
        </div> -->
      </div>

      <!-- <div v-if="this.$auth.user.vendor_role == '0'">
        <a  @click="maintenanceTextModal = !maintenanceTextModal" class="maintenance_text_modal" >
          <i class="bx bx-cog font-size-20 align-middle" v-b-tooltip.hover.v-light :title="trans.get('__JSON__.Maintenance')"></i>
        </a>
      </div> -->
      <!-- <div v-if="this.$auth.user.vendor_role == '2'">
        <router-link  :to="{ name: 'release-notes-list' }" class="release_note">
          <img :src='config.media_path+"/images/Announcement_icons_white.png"' alt="No image" v-b-tooltip.hover.v-light :title="trans.get('__JSON__.Release Notes')">
        </router-link>
      </div> -->


    <template v-if="maintenanceTextModal">
      <maintenanceTextModal
        @modalClose="maintenanceTextModalClose"
        :openModal="maintenanceTextModal"
      />
    </template>
    </div>
  </footer>
  <!-- end footer -->
</template>

<style scoped>
.maintenance_text_modal {
  position: fixed;
  bottom: 5px;
  background-color: rgb(255, 255, 255);
  color: rgb(85 110 230);
  text-align: center;
  right: 12px;
  height: 50px;
  display: flex;
  width: 50px;
  border-radius: 50%;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.release_note {
  position: fixed;
  bottom: 5px;
  /* background-color: rgb(239 115 66); */
  background-color: rgb(210 204 201 / 43%);
  text-align: center;
  right: 12px;
  height: 50px;
  display: flex;
  width: 50px;
  border-radius: 50%;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.release_note img {
  height: 35px;
  width: 35px;
}

</style>
