<script>
import NavBar from "../components/nav-bar";
import SideBar from "../components/side-bar";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";
import { clientService } from "../services";

export default {
  components: { NavBar, SideBar, RightBar, Footer },
  data() {
    return {
      isMenuCondensed: false,
      system : []
    };
  },
  created: () => {
    if(document.body.removeAttribute("data-layout", "vertical") && document.body.removeAttribute("data-topbar", "dark"))
    {
      document.body.removeAttribute("data-layout", "horizontal");
      document.body.removeAttribute("data-layout-size", "boxed");
    }
    
  },
  mounted() {
    this.getSystem();
  },
  methods: {
    getSystem() {
      clientService.getSystem().then((response) => {
          this.system = response.data.result.text;
      });
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

     if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>

<template>
<div layout="vertical">
  <div id="layout-wrapper">
    <!-- <NavBar /> -->
    <SideBar :is-condensed="isMenuCondensed" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <marquee behavior="scroll" id="marquee" gap="0" truespeed="" scrollamount="2" scrolldelay="50" onmouseover="this.stop();" onmouseout="this.start();" v-if="system && system != 'null' && $auth.user.vendor_role != '1' && system > 0">
        <p>{{system}}</p>
      </marquee>
        <!-- :style="[!$auth.partner_login ? {'margin-top': '110px !important'} : {'margin-top': '#70px'}]" -->
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
    <RightBar />
  </div>
</div>
</template>
<!-- <style>
#marquee{
  background-color: red;
  color: #fff;
}
#marquee p{
    padding-top: 5px;
    font-size: 12px;
    font-weight: 500;
}
</style> -->