
// Vendor services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const vendorService = {
    // get vendor 
    // info: sortDesc : Boolean

    getVendorList: (formData) => window.axios.post(routes.getVendorApi, formData),

    // new 07-01-2022
    getAllVendorList: (formData) => window.axios.post(routes.getAllVendorApi, formData),
    // new 07-01-2022

    // vendor export to csv
    exportToCsv:(formData) => window.axios.post(routes.getVendorDetailApi, formData),

    // get rating
    getRatingList: (formData) => window.axios.post(routes.getRatingApi, formData),

    // get category
    getCategoryList: (formData) => window.axios.post(routes.getVendorCategoryApi, formData),

    // add restaurant category
    addMenuCategory: (formData) => window.axios.post(routes.addRestaurantCategoryApi, formData, { headers: multipart_headers() }),

    // get menucategorybyID

    getMenuCategoryById: (formData) => window.axios.post(routes.getMenuCategoryByIdApi, formData),

    // update menucategoryByID

    updateMenuCategoryById: (formData) => window.axios.post(routes.updateRestaurantCategoryApi, formData, { headers: multipart_headers() }),

    // delete menucategory

    deleteMenuCategory: (formData) => window.axios.post(routes.deleteMenuCategoryApi, formData),

    // add vendor

    addVendor: (formData) => window.axios.post(routes.addVendorApi, formData, { headers: multipart_headers() }),


    // get vendorcuisine

    getVendorCuisine: (query) => window.axios.post(routes.getRestaurantCuisineApi, query),

    // get resCategory

    getResCategory: () => window.axios.get(routes.getRestaurantCategoryApi),

    // get restaurantbyID

    getRestaurantById: (formData) => window.axios.post(routes.getRestaurantbyIdApi, formData),

    getBusinessProfile: () => window.axios.get(routes.getBusinessProfileApi),

    updateBusinessProfile: (formData) => window.axios.post(routes.updateBusinessProfileApi, formData),

    updateVendorInfo: (formData) => window.axios.post(routes.updateVendorInfoApi, formData),

    getRestaurantRating: (formData) => window.axios.post(routes.getRestaurantRatingApi,formData),

    getLanguage: () => window.axios.get(routes.getLanguageApi),

    getVendorLanguage: (formData) => window.axios.post(routes.getVendorLanguageApi, formData),

    // Get Menu APi 
    getRestaurantMenu: (formData) => window.axios.post(routes.getRestaurantMenuApi, formData),
    //Get Item by Menu id
    getItemByMenuID: (formData) => window.axios.post(routes.getItemByMenuIDApi, formData),
    
    getMenuById: (formData) => window.axios.post(routes.getMenuByIdApi, formData),
    //add Menu 
    addMenu: (formData) => window.axios.post(routes.addMenuApi, formData, { headers: multipart_headers() }),

    editMenu: (formData) => window.axios.post(routes.editMenuApi, formData),

    updateMenuStatus: (formData) => window.axios.post(routes.updateMenuStatusApi, formData),
    
    addItmeMenu: (formData) => window.axios.post(routes.addItmeMenuApi, formData),
    
    getResTimeSlot: (formData) => window.axios.post(routes.getResTimeSlotApi, formData),
    
    getMenuCategories: (formData) => window.axios.post(routes.getMenuCategoriesApi, formData),
    
    getMenuItem: (formData) => window.axios.post(routes.getMenuItemApi, formData),
    
    deleteMenu: (formData) => window.axios.post(routes.deleteMenuApi, formData),

    deleteMenuWithItem: (formData) => window.axios.post(routes.deleteMenuWithItemApi, formData),

    deleteMenuItem: (formData) => window.axios.post(routes.deleteMenuItemApi, formData),
    
    menuItemEdit: (formData) => window.axios.post(routes.menuItemEditApi, formData),
    
    getCustomization: (formData) => window.axios.post(routes.getCustomizationApi, formData),

    addMenuImage: (formData) => window.axios.post(routes.addMenuImageApi, formData),
    
    getMenuImage: (formData) => window.axios.post(routes.getMenuImageApi, formData),
    
    importMenuItem: (formData) => window.axios.post(routes.importMenuItemApi, formData),

    changeRestPassword: (formData) => window.axios.post(routes.changeResPasswordApi,formData),

    // Change Res Password
    changeResPassword: (formData) => window.axios.post(routes.changerespasswordApi, formData),


    getDeliveryZones: ()    =>  window.axios.get(routes.getDeliveryZonesApi),

    updateUserPassword: (data) => window.axios.post(routes.updateUserPasswordApi, data),

    updateMenuOrder: (data) => window.axios.post(routes.updateMenuOrderApi, data),

    updateItemOrder: (data) => window.axios.post(routes.updateItemOrderApi, data),

    updateItemAvailability: (data) => window.axios.post(routes.updateItemAvailabilityApi, data),

    vendorSignup: (data) => window.axios.post(routes.vendorSignupApi, data),

    menuItemExport: (data) => window.axios.post(routes.menuItemExportApi, data),

    updateMenuCatOrder: (data) => window.axios.post(routes.updateMenuCatOrderApi, data),

    //QrCode
    getQrCode: (formData) => window.axios.post(routes.getQrCodeApi,formData),

    updateMenuCategoryStatus: (formData) => window.axios.post(routes.updateMenuCategoryStatusApi, formData),
    getFirstRestaurant: (formData) => window.axios.post(routes.getFirstRestaurantApi, formData)
}