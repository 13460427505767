<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
};
</script>

<template>
  <!-- start page title -->
  <div class="row header-new">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between icon-main-page">
        <div class="page-title-left">
          <div class="avatar-xs mr-3">
            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
              <slot name="icon"><i class="bx bx-group"></i></slot>
            </span>
          </div>
          <slot><h4 class="mb-0 font-size-18">{{ title }}</h4></slot>
        </div>
        <div class="page-title-right">
          <slot name="right"></slot>
        </div>
        <!-- <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <slot></slot> -->
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
