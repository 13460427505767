import { routes } from '../config/api_routes'

export const deliveryZoneSettingService = {

    // get all delivery zone
    getAllDeliveryZone: () => window.axios.get(routes.getAllDeliveryZoneApi),

    getDeliveryZoneSetting: (data) => window.axios.post(routes.getDeliveryZoneSettingApi, data),
    updateDeliveryZoneSetting: (data) => window.axios.post(routes.updateDeliveryZoneSettingApi, data),
    
    getDriverDeliveryZoneSetting: (data) => window.axios.post(routes.getDriverDeliveryZoneSettingApi, data),
    updateDriverDeliveryZoneSetting: (data) => window.axios.post(routes.updateDriverDeliveryZoneSettingApi, data),
}