<script>
import { success_message, error_message } from '../Helper/helper';
import { clientService } from "../services";



export default {
  props: ["openModal"],
  data() {
    return {
      typeform: {
        maintenance_text: "",
      },
    };
  },
  mounted() {
    this.getSystem();
  },
  methods: {
    getSystem() {
      clientService.getSystem().then((response) => {
          this.typeform.maintenance_text = response.data.result.text;
          console.log(this.typeform.maintenance_text);
      });
    },
    systemInIt() {
        const fd = new FormData();
        fd.append("maintenance_text", this.typeform.maintenance_text);
        clientService.updateSystem(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            // this.$router.push({ name: "banner-edit" });
            this.itemModalClose();
          } else {
            error_message(response.data.message);
          }
        });
    },
    itemModalClose() {
      this.openModal = false;
      this.$emit("modalClose", false);
    }
  },
};
</script>

<template>
  <div>
    <b-modal
      v-model="openModal"
      hide-footer
      scrollable
      :title='trans.get("__JSON__.Maintenance")'
      title-class="font-18"
      @hidden="itemModalClose()"
      centered
      size="lg"
    >
      <template >
          <form @submit.prevent="systemInIt">
          <!-- Maintenance Start -->
          <div class="form-group">
              <label class="control-label">{{ trans.get('__JSON__.Maintenance Text') }}</label>
              <input
                  id="maintenance_text"
                  name="maintenance_text"
                  type="text"
                  v-model="typeform.maintenance_text"
                  placeholder="Enter Maintenance Text"
                  class="form-control"
              />
          </div>
        <div class="row">
            <div class="col-sm-6">
                <template>
                    <button
                    type="submit"
                    class="btn btn-primary mr-1 mt-3"
                    >{{ trans.get('__JSON__.Update') }}</button>
                </template>
            </div>
        </div>
          <!-- Maintenance End -->
          </form>
      </template>
      <template slot="modal-footer">
        <div class="w-100">
          <b-button variant="primary" class="float-right add_item_">
            {{ trans.get("__JSON__.Save") }}</b-button
          >
          <b-button class="float-left" @click="itemModalClose($event)">{{
            trans.get("__JSON__.Close")
          }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
