<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import config from "../../config";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { date_ranges} from '../../Helper/helper';
import { required, requiredIf } from "vuelidate/lib/validators";
import { error_message, success_message } from "../../Helper/helper";
import { routes } from "../../config/api_routes";
import { RestaurantTableService } from "../../services";
import moment from "moment";
export default {
  components: {Layout,PageHeader, pagination,DateRangePicker,RestaurantTableService },
  data() {
     let startDate = this.moment().startOf('day').toDate();
     let endDate = this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      config: config,
      limit: 1,
      AddBtnDisable:false,
      allocaed:"",
      free:"",
      floor:0,
      restaurantModal: false,
      editRestaurantModal: false,
      title: "Table",
      table_status:"free",
      search: "",
      sortBy: "id",
      sortDesc: true,
      status:"",
      selectedRestaurant: [],
      selected:"",
      vendor_id: "",
      loading: true,
      restaurant:[],
      edittable: {
        id: "",
        table_name: "",
        table_capacity:"",
        table_number:"",
        alias:"",
        status: "",
      },
      filters: {
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      date_ranges:date_ranges,
      fields: [],
      data: [],
      currentPage: 1,
      paginations: {},
       typeform: {
        restaurant_id: this.$route.params.restaurant_id,
        table_name: "",
        table_capacity: "",
        table_number:"",
        alias: "",
        },
    };
  },
  mounted() {
    this.vendor_id = this.$auth.getVendorId();
    this.getTable();
    this.checkSecondName();
    this.Dynamic_field();
    this.dateRange =this.changeSettingRange();
    this.filters = {
        ...this.filters,
      start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
    }
  },
    validations: {
    typeform: {
      table_name: {
        required,
      },
      table_capacity: {
        required,
      },
      table_number: {
        required,
      },
      alias:{
        required,
      },
     
    },
    edittable: {
      table_name: {
      required,
      },
      table_capacity: {
        required,
      },
      table_number: {
        required,
      },
      alias:{
        required,
      },
    },
  },
  methods: {
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
    },
       updateValues(date) {
      this.filters = {
        ...this.filters,
       start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getTable();
    },
    changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    },
     onChange(event) {
      // console.log(event.target.value, this.status);
    },
     addTable() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else{
     this.selected = "";
        const fd = new FormData();
        fd.append("restaurant_id",this.typeform.restaurant_id);
        fd.append("table_name", this.typeform.table_name);
        fd.append("table_capacity", this.typeform.table_capacity);
        fd.append("table_number", this.typeform.table_number);
        fd.append('alias',this.typeform.alias);
        RestaurantTableService.add_table(fd)
        .then((response) => {
            if (response.data.code === 200) {
               success_message(response.data.message);
               this.typeform.table_name = this.typeform.table_capacity = this.typeform.table_number  =  this.typeform.alias ='';
               this.restaurantModal = false;
               this.getTable();
            }else {
                  this.AddBtnDisable = false;
                  error_message(response.data.message);
            }
        });
      }
    },
   getTable(page) {
      this.loading = true;
      RestaurantTableService
        .get_table_list({
          vendor_id:this.$auth.getVendorId(),
          search: this.search,
          table_status: this.table_status,
          sortBy: this.sortBy,
          orderBy: "desc",
          page: page ? page : 1,
          restaurant_id: this.$route.params.restaurant_id,
          id:this.id,
           ...this.filters,
        })
        .then((response) => {
          this.currentPage = page ? page : 1;
          this.loading = false;
          this.data = response.data.result.data;
          this.paginations = response.data.result;
        });
    },
    updatetable() {
      this.$v.edittable.$touch();
      if (this.$v.edittable.$invalid) {
        return;
      } else{
        var datas = {
          id: this.data.id,
          table_name: this.data.table_name,
          table_capacity: this.data.table_capacity,
          table_number:this.data.table_number,
          alias:this.data.alias,
          table_status:this.data.table_status, 
          status:this.data.status,
        }
            RestaurantTableService.update_table(this.edittable).then((response) => {
            if (response.data.code === 200) {
            success_message(response.data.message);
            this.editRestaurantModal = false;
            this.getTable();
            } else {
              error_message(response.data.message);
            }
          });
      }
    },
    editTable(id) {
       this.fetchTable(id);
     },
    fetchTable(id) {
     return RestaurantTableService.edit_table({id: id}) .then((response) => {
       if (response.data.code === 200) {
          this.edittable = response.data.result;
         } else {
          this.$toasted.error(response.data.message);
        }
      });
    },
    base64_encode(id) {
      return btoa(id);
    },
    searchTable() {
        this.getTable();
    },
    sortingChanged() {
      let sort =
        this.sortDesc == true ? false : this.sortDesc == false ? true : true;
      this.sortDesc = sort;
      this.getTable();
    },
    checkSecondName() {
      if (this.$auth.langauges[1].name === "No Secondary Language") {
        this.secondName_view = false;
      } else {
        this.secondName_view = true;
      }
    },
    Dynamic_field() {
      if (this.secondName_view == true) {
        let field = [        
          { key: "id", label: "ID", sortable: true ,thStyle: { width: "10%" },},
          { key: "table_name", label: "Table Name", sortable: true ,thStyle: { width: "10%" },},
           { key: "table_capacity", label: "Table Capacity", sortable: true ,thStyle: { width: "10%" },},
          { key: "table_number", label: "Table Number", sortable: false ,thStyle: { width: "10%" },},
          { key: "alias", label: "Alias", sortable: false ,thStyle: { width: "10%" },},
          { key: "table_status", label: "Table Status", sortable: false ,thStyle: { width: "10%" },},
          // { key: "status", label: "Status", sortable: false ,thStyle: { width: "10%" },},
           { key: "created_at", label: "Date", sortable: false ,thStyle: { width: "20%" },},
          { key: "action", label: "Action", sortable: false ,thStyle: { width: "20%" },},
        ];
        this.fields = field;
      } else {
        let field = [  
          { key: "id", label: "ID", sortable: true ,thStyle: { width: "10%" },},
          { key: "table_name", label: "Customer Name", sortable: true ,thStyle: { width: "10%" },},
           { key: "table_capacity", label: "Customer Name", sortable: true ,thStyle: { width: "10%" },},
          { key: "table_number", label: "Mobile Number", sortable: false ,thStyle: { width: "10%" },},
          { key: "alias", label: "No of Person", sortable: false ,thStyle: { width: "30%" },},
           { key: "table_status", label: "Table Status", sortable: false ,thStyle: { width: "10%" },},
          { key: "status", label: "Status", sortable: false ,thStyle: { width: "10%" },},
          { key: "created_at", label: "Date", sortable: false ,thStyle: { width: "20%" },},
          { key: "action", label: "Action", sortable: false ,thStyle: { width: "20%" },},
        ];
        this.fields = field;
      }
    },
  },
  computed: {
    computedFields() {
      if (this.$auth.partner_login) {
        return this.fields.filter(field => !field.requiresAdmin);
      } else {
        return this.fields;
      }
    },
       getSelected:function(){
      const { start_date, end_date } = this.filters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
};
</script>
<template>
  <Layout>
  <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3" style="margin-top:0">
                  <span class=" avatar-title rounded-circle bg-soft-primarytext-primary font-size-18">
                    <i class="bx bx-box"></i>
                  </span>
              </div>
            <div class="report-tag">
                <h4 class="mb-0 font-size-18">POS</h4>
                  <p  v-if="!$auth.partner_login">
                  {{ trans.get("Restuarant Table") }}
                  </p>
            </div>
          </div>
      
          <div class="page-title-right d-flex">
            <button v-if="$auth.hasPermission(config.permissions.pos_write)" type="button"
                  class="btn btn-success btn-rounded mr-2 add-btn" v-b-modal.restaurantModal>
                  <i class="mdi mdi-plus mr-1"></i>
                  {{ trans.get("Add Table") }}
                </button>
                      <div>
                <router-link v-if="$auth.hasPermission(config.permissions.pos_write)" :to="{ name: 'Table View' }"
                  class="btn btn-success btn-rounded mr-2 add-btn">
                  {{ trans.get("Table View") }}
                </router-link>
            </div>
            </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-4">
                <div class="form-group ml-2">
                  <div class="date-range-list">
                    <label>{{trans.get('__JSON__.Date')}} :</label>
                     <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        class="w-100"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('DD-MM-YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('DD-MM-YYYY') | date }}
                        </template>
                      </date-range-picker>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get("__JSON__.Status") }} :</label>
                    <select class="custom-select" v-model="table_status" @change="searchTable()">
                      <option value="">{{ trans.get("__JSON__.All") }}</option>
                      <option value="free" selected>
                        {{ trans.get("Free") }}
                      </option>
                      <option value="allocated">
                        {{ trans.get("Allocated") }}
                      </option>
                      <option value="paid">
                        {{ trans.get("Paid") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                  <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchTable()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get("__JSON__.Search") }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input type="text" class="form-control" id="search" @input="searchTable()" v-model="search"
                          :placeholder="
                            trans.get('__JSON__.Search Placeholder')
                          " />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 table-main-list table-responsive">
              <b-table 
              :items="data" 
              :fields="computedFields" 
              :sort-by.sync="sortBy" 
              :no-local-sorting="true"
              @sort-changed="sortingChanged" 
              :busy="loading" show-empty>
              
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
                  </div>
                </template>

                <template v-slot:cell(table_name)="data">
                  <div v-if="data.item.table_name">
                    <button type="button" class="btn btn-rounded btn-sm">
                      {{ data.item.table_name }}                      
                    </button>
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <div class="d-flex">
                  <button v-if="$auth.hasPermission(config.permissions.pos_write)" type="button"
                  class="btn btn-primary btn-sm btn-rounded" v-b-modal.editRestaurantModal  @click="editTable(data.item.id)">
                  {{ trans.get("__JSON__.Edit") }}
                </button>
                  </div>
                </template>
                <template #empty>
                  <p class="text-center">
                    {{ trans.get("No Table Found") }}
                  </p>
                </template>
              </b-table>
            </div>
            <div class="row col-sm-5 offset-sm-7">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <pagination :data="paginations" :limit="limit" @pagination-change-page="getTable"></pagination>
                   </ul>
                </div>
              </div>
            </div>
          </div>
          <b-modal id="restaurantModal" v-model="restaurantModal" scrollable :title="trans.get('Add Table')"
            title-class="font-18">
            <b-form @submit.prevent="addTable" ref="addTableref">
              <div class="form-group">
                <input id="restaurant_id" name="restaurant_id" type="hidden" class="form-control" />
              </div>
               <div class="form-group">
                <input id="floor_id" name="floor_id" type="hidden" class="form-control" />
              </div>
                <div class="form-group">
                <input id="table_status" name="table_status" type="hidden" class="form-control" />
              </div>
              <div class="row">
                    <div class="col-12">
                       <div class="mb-3">
                      <label class="control-label required">{{
                        trans.get("Table Name")
                        }}</label>
                      <input id="table_name" v-model="$v.typeform.table_name.$model" name="table_name"
                        :class="{ 'is-invalid': $v.typeform.table_name.$error }" type="text"
                        placeholder="Enter Table name" class="form-control" />
                      <div v-if="$v.typeform.table_name.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.table_name.required">{{ trans.get("Enter Table Name") }}.</span>
                      </div>
                      </div> 
                    </div>
                </div>   
                   <div class="row">
                 <div class="col-12">
                     <div class="mb-3">
                        <label for="Table Capacity">Table Capacity</label
                      ><input
                        id="table_capacity"
                        v-model="$v.typeform.table_capacity.$model"
                        type="number"
                        placeholder="Enter Table Capacity"
                        class="form-control"
                        name="table_capacity"
                        :class="{ 'is-invalid': $v.typeform.table_capacity.$error }"
                      />
                      <div
                      v-if="$v.typeform.table_capacity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.table_capacity.required">
                      {{ trans.get("Enter Table Capacity") }}</span>
                  </div>
                </div>
              </div>
          </div>        
                <div class="row">
                 <div class="col-12">
                     <div class="mb-3">
                        <label for="Table Number">Table Number</label
                      ><input
                        id="table_number"
                        v-model="$v.typeform.table_number.$model"
                        type="number"
                        placeholder="Enter Table Number"
                        class="form-control"
                        name="table_number"
                        :class="{ 'is-invalid': $v.typeform.table_number.$error }"
                      />
                      <div
                      v-if="$v.typeform.table_number.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.table_number.required">
                      {{ trans.get("Enter Table Number") }}</span>
                  </div>
                </div>
              </div>
          </div>
              <div class="row">
                 <div class="col-12">
                     <div class="mb-3">
                        <label for="Table Number">Table Number</label
                      ><input
                        id="table_number"
                        v-model="$v.typeform.table_number.$model"
                        type="number"
                        placeholder="Enter Table Number"
                        class="form-control"
                        name="table_number"
                        :class="{ 'is-invalid': $v.typeform.table_number.$error }"
                      />
                      <div
                      v-if="$v.typeform.table_number.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.table_number.required">
                      {{ trans.get("Enter Table Number") }}</span>
                  </div>
                </div>
              </div>
          </div>
              <div class="row">
                 <div class="col-12">
                    <div class="mb-3">
                        <label for="alias">Alias</label>
                        <input
                        type="text"
                        id="alias"
                        v-model="$v.typeform.alias.$model"
                        placeholder="Enter Alias"
                        class="form-control"
                         name="alias"
                        :class="{ 'is-invalid': $v.typeform.alias.$error }"/>
                        <div
                          v-if="$v.typeform.alias.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.typeform.alias.required">{{ trans.get("Enter Number of Person") }}.</span>
                        </div>
                    </div>
                  </div>
               </div>
             </b-form>
            <template slot="modal-footer">
              <div class="w-100">
                <b-button variant="primary" class="float-right" @click="addTable()">{{ trans.get('Add Table') }}
                </b-button>
                <b-button class="float-left" @click="restaurantModal = false">{{trans.get('__JSON__.Close')}}</b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="editRestaurantModal" v-model="editRestaurantModal" scrollable :title="trans.get('Edit Restaurant Table')"
            title-class="font-18">
            <b-form @submit.prevent="updateTable">
                <div class="form-group">
                  <input id="id" name="id" type="hidden" v-model="edittable.id" class="form-control" />
                </div>
              <div class="form-group">
                <input id="restaurant_id" name="restaurant_id" type="hidden" class="form-control" />
              </div>
             <div class="row">
                    <div class="col-12">
                       <div class="mb-3">
                      <label class="control-label required">{{
                        trans.get("Table Name")
                        }}</label>
                      <input id="table_name" v-model="$v.edittable.table_name.$model" name="customer_name"
                         type="text"
                        placeholder="Enter Table name" class="form-control"
                         :class="{ 'is-invalid': $v.edittable.table_name.$error }" />
                    <div v-if="$v.edittable.table_name.$error" class="invalid-feedback">
                        <span v-if="!$v.edittable.table_name.required">{{ trans.get("Enter Table Name") }}.</span>
                      </div>
                      </div> 
                    </div>
                </div>
              <div class="row">
                 <div class="col-12">
                     <div class="mb-3">
                        <label for="Capacity">Table Capacity</label
                      ><input
                        id="table_capacity"
                        v-model="$v.edittable.table_capacity.$model"
                        type="number"
                        placeholder="Enter Table Capcity"
                        class="form-control"
                        name="table_capacity"  :class="{ 'is-invalid': $v.edittable.table_capacity.$error }" />
                        <div v-if="$v.edittable.table_capacity.$error" class="invalid-feedback">
                        <span v-if="!$v.edittable.table_capacity.required">{{ trans.get("Enter Table Capacity") }}.</span>
                      </div>
                    </div>
                    </div>
                </div>
              <div class="row">
                 <div class="col-12">
                    <div class="mb-3">
                      <label for="Table Number">Table Number</label
                      ><input
                        type="number"
                        id="table_number"
                        
                        v-model="$v.edittable.table_number.$model"
                        placeholder="Table Number"
                        class="form-control"
                         name="table_number"
                          :class="{ 'is-invalid': $v.edittable.table_number.$error }" />
                    <div v-if="$v.edittable.table_number.$error" class="invalid-feedback">
                        <span v-if="!$v.edittable.table_number.required">{{ trans.get("Enter Table Number") }}.</span>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row">
                 <div class="col-12">
                    <div class="mb-3">
                      <label for="alias">Alias</label
                      ><input
                        type="text"
                        id="alias"
                        v-model="$v.edittable.alias.$model"
                        placeholder="Alias"
                        class="form-control"
                         name="alias"
                          :class="{ 'is-invalid': $v.edittable.alias.$error }" />
                    <div v-if="$v.edittable.alias.$error" class="invalid-feedback">
                        <span v-if="!$v.edittable.alias.required">{{ trans.get("Enter Alias") }}.</span>
                      </div>
                    </div>
                  </div>
              </div>
            </b-form>
            <template slot="modal-footer">
              <div class="w-100">
                <b-button variant="primary" class="float-right" @click="updatetable">{{ trans.get('Update Table') }}
                </b-button>
                <b-button class="float-left" @click="editRestaurantModal = false">{{trans.get('__JSON__.Close')}}</b-button>
              </div>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.custome_width{
  width: 50px!important;
}
.reportrange-text .fa{
  display: none !important;
}
</style>
