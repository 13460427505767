// Restaurant Tag services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const restaurantTagservice = {
    // get Restorunt services
    getResTag: (formData) => window.axios.post(routes.getRestaurantTagApi,formData),

    addResTag:(formData)=>window.axios.post(routes.addRestaurantTagApi,formData),

    editResTag:(formData)=>window.axios.post(routes.editRestaurantTagApi,formData),

    updateResTag:(formData)=>window.axios.post(routes.updateRestaurantTagApi,formData),

    deleteResTag:(formData)=>window.axios.post(routes.deleteRestaurantTagApi,formData),
}