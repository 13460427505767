<script>
import simplebar from "simplebar-vue";

import SideNav from "./side-nav";
import NavBarOption from "./nav-bar-option";

export default {
  components: { simplebar, SideNav,NavBarOption },
  props: {
    isCondensed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60
      }
    };
  },

  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  }
};
</script>

<template>
  <div class="sidebar-main">
  <!-- ========== Left Sidebar Start ========== -->
  <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

  <div class="vertical-menu">
    <simplebar v-if="!isCondensed" :settings="settings" class="h-100">
      <SideNav />
    </simplebar>

    <simplebar v-else class="h-100">
      <SideNav />
    </simplebar>
    <NavBarOption/>
  </div>
</div>
  <!-- Left Sidebar End -->
</template>
<style>
  @media (min-width:992px){
    .sidebar-main{
      position: fixed;
      z-index: 9;
    }
  }

  @media (max-width:991px){
    .sidebar-main #vertical-menu-btn{
      position: fixed;
      right: 10px;
      top: -5px;
      z-index: 11111;
    }
    .header-new{
      padding-right: 40px;
      
    }
  }
</style>
