import { routes } from "../config/api_routes";

export const WalletDashboardService = {
    // Wallet Dashboard
    getWalletDashboard: (data) =>
        window.axios.post(routes.getWalletDashboardApi, data),

    getWalletFinance: (data) =>
        window.axios.post(routes.getWalletFinance, data),

    getWalletFinanceCsv: (data) =>
        window.axios.post(routes.getWalletFinanceCsvApi, data),

    getMonthWiseData: (formData) => {
        return window.axios.post(routes.getMonthWiseDataApi, formData);
    },

    getWalletFinanceMonth: (month, status) =>
        window.axios.get(routes.getWalletFinanceMonthApi, {
            params: { month, status },
        }),

    getWalletFinanceMonthCsv: (month) =>
        window.axios.get(routes.getVendorFinanceMonthCsvApi, {
            params: { month },
        }),
};
