import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const RestaurantcartService = {
    // get Restorunt services
    //getwaitingrestaurant:(formData) => window.axios.post(routes.getWaitingrestaurant,formData),

   
    addTablecart:(formData)=>window.axios.post(routes.addTablecartApi,formData),

    editTablecart:(formData)=>window.axios.post(routes.editTablecartApi,formData),

    updateTablecart:(formData)=>window.axios.post(routes.updateTablecartApi,formData),

    getTablecart: (formData) => window.axios.post(routes.getTablecartApi,formData),
   
    //updateTableStatus:(formData)=>window.axios.post(routes.updateTablecartStatusApi,formData),

}