<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import config from "../config";
import { menuItems } from "./menu";
import { vendormenuItems } from "./vendor";
import { resellerMenuItems } from "./reseller";
import { saasmenuItems } from "./saas";
import { userAccessService, settingService } from "../services";
import {
  get_partner_login,
  get_permitted_menus,
  get_user,
  set_user,
} from "../Helper/helper";

export default {
  data() {
    return {
      config: config,
      menuItems: this.$auth.isClientLogin()
        ? resellerMenuItems
        : this.$auth.partner_login
        ? vendormenuItems
        : this.$auth.isSaasClientLogin()
        ? saasmenuItems
        : this.$menus || menuItems,
      vendormenuItems: vendormenuItems,
      permissions: this.$auth.user && this.$auth.user.access,
      setting: [],
    };
  },
  mounted() {
    this.getPermissions();
    this.getAppSetting();
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {
    getAppSetting() {
      // settingService.getAppSettingApi().then((response) => {
      // this.setting = response.data.result;
      for (let menuIndex = 0; menuIndex < this.menuItems.length; menuIndex++) {
        if (this.menuItems[menuIndex]) {
          if (this.menuItems[menuIndex].subItems) {
            for (
              let subIndex = 0;
              subIndex < this.menuItems[menuIndex].subItems.length;
              subIndex++
            ) {
              if (this.menuItems[menuIndex].subItems[subIndex]) {
                if (this.menuItems[menuIndex].subItems[subIndex].label == "Driver") {
                  this.menuItems[menuIndex].subItems[
                    subIndex
                  ].label = this.$auth.getDriverName();
                }
                if (this.menuItems[menuIndex].subItems[subIndex].label == "Vendors") {
                  this.menuItems[menuIndex].subItems[
                    subIndex
                  ].label = this.$auth.getRestaurantName();
                }
                if (this.menuItems[menuIndex].subItems[subIndex].label == "Brand") {
                  this.menuItems[menuIndex].subItems[
                    subIndex
                  ].label = this.$auth.getBrandName();
                }
                if (this.menuItems[menuIndex].subItems[subIndex].label == "Chat") {
                  if (!this.$auth.isChatActive()) {
                    this.menuItems[menuIndex].subItems.splice(subIndex, 1);
                  }
                }
                if (this.menuItems[menuIndex].label == "Chat") {
                  if (!this.$auth.isChatActive()) {
                    this.menuItems[menuIndex].splice(subIndex, 1);
                  }
                }
                if (
                  this.menuItems[menuIndex].subItems[subIndex] &&
                  this.menuItems[menuIndex].subItems[subIndex].label ==
                    "Web Configurations"
                ) {
                  if (!this.$auth.isWebOrderingActive()) {
                    this.menuItems[menuIndex].subItems.splice(subIndex, 1);
                  }
                }
                if (
                  this.menuItems[menuIndex].subItems[subIndex] &&
                  this.menuItems[menuIndex].subItems[subIndex].label == "Call Center"
                ) {
                  if (!this.$auth.isCallcenterActive()) {
                    this.menuItems[menuIndex].subItems.splice(subIndex, 1);
                  }
                }
                if (this.$auth.setting && this.$auth.setting.store_selection == 0) {
                  if (
                    this.menuItems[menuIndex].subItems[subIndex] &&
                    this.menuItems[menuIndex].subItems[subIndex].label ==
                      "Business Category"
                  ) {
                    this.menuItems[menuIndex].subItems.splice(subIndex, 1);
                  }
                  if (
                    this.menuItems[menuIndex].subItems[subIndex] &&
                    (this.menuItems[menuIndex].subItems[subIndex].label == "Brand" ||
                      this.menuItems[menuIndex].subItems[subIndex].id == 10)
                  ) {
                    this.menuItems[menuIndex].subItems.splice(subIndex, 1);
                  }
                }
              }
            }
          } else {
            if (
              this.menuItems[menuIndex] &&
              this.menuItems[menuIndex].label == "Catalog"
            ) {
              this.menuItems[menuIndex].label = this.$auth.getCatalogName();
            }
            if (this.$auth.partner_login) {
              if (
                this.menuItems[menuIndex] &&
                this.menuItems[menuIndex].label == "Selling Time slot"
              ) {
                this.menuItems[menuIndex].label = this.trans.get(
                  "__JSON__.Menu time-slot"
                );
              }
            }
          }
        }
      }
      //Dt.27/05/2022, Bellow are use static index so if some menu not come then it will create issue
      // if (this.menuItems[0]) {
      //   if (this.menuItems[0].subItems && this.menuItems[0].subItems[2]) {
      //     this.menuItems[0].subItems[2].label = this.$auth.getDriverName();
      //   }
      // }
      // if (this.menuItems[1]) {
      //   if (this.menuItems[1].subItems && this.menuItems[1].subItems[0]) {
      //     this.menuItems[1].subItems[0].label = this.$auth.getRestaurantName();
      //   }
      //   if (this.menuItems[1].subItems && this.menuItems[1].subItems[1]) {
      //     this.menuItems[1].subItems[1].label = this.$auth.getDriverName();
      //   }
      // }
      // if (this.menuItems[4]) {
      //   if (this.menuItems[4].subItems && this.menuItems[4].subItems[0]) {
      //     this.menuItems[4].subItems[0].label = this.$auth.getRestaurantName();
      //   }
      //   if (this.menuItems[4].subItems && this.menuItems[4].subItems[1]) {
      //     this.menuItems[4].subItems[1].label = this.$auth.getDriverName();
      //   }
      // }
      // });
      // if(this.$auth.setting && this.$auth.setting.store_selection == 0) {
      //   if (this.menuItems[5]) {
      //     if (this.menuItems[5].subItems) {
      //       if (this.menuItems[5].subItems[0] && this.menuItems[5].subItems[0].id == 18) { //Business Category
      //         this.menuItems[5].subItems.splice(0,1);
      //       }
      //     }
      //   }
      //   if (this.menuItems[1]) {
      //     if (this.menuItems[1].subItems) {
      //       if (this.menuItems[1].subItems[3] && this.menuItems[1].subItems[3].id == 10) { //Brand
      //         this.menuItems[1].subItems.splice(3,1);
      //       }
      //     }
      //   }
      // }
      // if (!this.$auth.isWebOrderingActive()) {
      //   if (this.menuItems[6]) {
      //     if (this.menuItems[6].subItems) {
      //       if (this.menuItems[6].subItems[2] && this.menuItems[6].subItems[2].id == 34) { //Web Settings
      //         this.menuItems[6].subItems.splice(2,1);
      //       }
      //     }
      //   }
      // }
      // if (!this.$auth.isCallcenterActive()) {
      //   if (this.menuItems[0]) {
      //     if (this.menuItems[0].subItems) {
      //       if (this.menuItems[0].subItems[4] && this.menuItems[0].subItems[4].id == 33) { //Call center
      //         this.menuItems[0].subItems.splice(4,1);
      //       }
      //     }
      //   }
      // }
      // if (this.$auth.partner_login) {
      //   if (this.menuItems[5]) {
      //     if (this.menuItems[5].id = 6) {
      //       this.menuItems[5].label = this.trans.get('__JSON__.Menu time-slot');
      //     }
      //   }
      // }
    },
    getPermissions() {
      if (this.$auth.partner_login) {
        this.vendormenuItems;
      } else if (this.$auth.isClientLogin()) {
        this.resellerMenuItems;
      } else if (this.$auth.isSaasClientLogin()) {
        this.saasmenuItems;
      } else {
        const user = get_user();
        userAccessService.getAuthPermissions().then((response) => {
          if (response.data.result.length > 0) {
            this.permissions = response.data.result;
            let access = response.data.result.map((p) => p.name);
            this.$auth.updatePermissions(access);
            this.menuItems = get_permitted_menus(menuItems, response.data.result);
            Vue.prototype.$menus = this.menuItems;
          } else {
            this.$auth.logout((name) => this.$router.push({ name }).catch(() => {}));
          }
        });
      }
    },
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      // const nextEl = event.target.nextSibling;
      const nextEl = event.target.nextElementSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    },
    coloredHeader() {
      document.body.setAttribute("data-topbar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
  },
};
</script>
<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
        <div class="collapse navbar-collapse active" id="topnav-menu-content">
          <ul
            class="navbar-nav metismenu list-unstyled mobilemenu"
            id="topnav-menu-content"
          >
            <!-- Menu data -->

            <li class="nav-item dropdown" v-for="(item, index) of menuItems" :key="index">
              <router-link
                class="nav-link dropdown-toggle arrow-none"
                tag="a"
                href="javascript: void(0);"
                @click.native="onMenuClick"
                :to="item.link ? item.link : ''"
              >
                <i :class="`bx ${item.icon}`"></i>
                {{ item.label }}
                <div v-if="hasItems(item)" class="arrow-down"></div>
              </router-link>
              <div
                class="dropdown-menu row active topnav-dropdown"
                aria-labelledby="topnav-dashboard"
                v-if="hasItems(item)"
              >
                <span v-for="(subitem, index) of item.subItems" :key="index">
                  <router-link
                    class="col dropdown-item side-nav-link-ref"
                    v-if="!hasItems(subitem)"
                    :to="subitem.link"
                    >{{ subitem.label }}</router-link
                  >
                  <div class="dropdown" v-if="hasItems(subitem)">
                    <a
                      class="dropdown-item"
                      href="javascript: void(0);"
                      @click="onMenuClick"
                    >
                      {{ subitem.label }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <router-link
                        v-for="(subSubitem, index) of subitem.subItems"
                        :key="index"
                        :to="subSubitem.link"
                        class="dropdown-item side-nav-link-ref"
                        >{{ subSubitem.label }}</router-link
                      >
                    </div>
                  </div>
                </span>
              </div>
            </li>
          </ul>
          <div
            class="btn-side-feedback hor-btn-side-feedback"
            v-if="!$auth.partner_login && $auth.user.vendor_role != '1'"
          >
            <!-- <a :href="config.tripetto_url" target="_blank">
              <button class="btn btn-rounded side-btn">
                <i class="bx bx-chat mr-1"></i>{{ trans.get('__JSON__.Feedback') }}
              </button>  
            </a> -->
            <router-link :to="{ name: 'help' }">
              <button class="btn btn-rounded side-btn">
                <i class="bx bx-help-circle mr-1"></i
                >{{ trans.get("__JSON__.Video Guide") }}
              </button>
            </router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<style scoped>
.metismenu {
  text-transform: capitalize;
}
.topnav-dropdown span {
  font-size: 14px !important;
}
@media all and (max-width: 991px) {
  .mobilemenu li:first-child {
    padding-left: 0.55rem !important;
  }
}
</style>
