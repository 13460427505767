// Dashboard services rest api calling

import { routes } from "../config/api_routes";

export const dashboardService = {
    // get dashboard

    getDashboard: () => {
        return window.axios.get(routes.getDashboardApi);
    },

    getUpdate: () => {
        return window.axios.get(routes.getUpdateApi);
    },

    getMonthWiseOrder: (formData) => {
        return window.axios.post(routes.getMonthWiseOrderApi, formData);
    },

    getMonthWiseSales: (formData) => {
        return window.axios.post(routes.getMonthWiseSalesApi, formData);
    },

    getMonthWiseEarning: (formData) => {
        return window.axios.post(routes.getMonthWiseEarning, formData);
    },

    getMonthWiseCancelOrder: (formData) => {
        return window.axios.post(routes.getMonthWiseCancelOrderApi, formData);
    },

    getMonthWiseDeliveredOrder: (formData) => {
        return window.axios.post(
            routes.getMonthWiseDeliveredOrderApi,
            formData
        );
    },

    getMonthWiseCustomer: (formData) => {
        return window.axios.post(routes.getMonthWiseCustomerApi, formData);
    },
    getAnalytics: (formData) => {
        return window.axios.post(routes.getAnalyticsApi, formData);
    },
    getMonthWiseTotalSalesDeliveredOrder: (formData) => {
        return window.axios.post(
            routes.getMonthWiseTotalSalesDeliveredOrderApi,
            formData
        );
    },
    getItemOrderStatics: (formData) => {
        return window.axios.post(routes.getItemOrderStaticsApi, formData);
    },
    getOrderCountTimingWiseStatics: (formData) => {
        return window.axios.post(
            routes.getOrderCountTimingWiseStaticsApi,
            formData
        );
    },
    getOderCountsStatics: (formData) => {
        return window.axios.post(routes.getOderCountsStaticsApi, formData);
    },
    getMonthWiseTotalDeliveredOrder: (formData) => {
        return window.axios.post(
            routes.getMonthWiseTotalDeliveredOrderApi,
            formData
        );
    },
    getThirtyDayDeliveredOrder: (formData) => {
        return window.axios.post(
            routes.getThirtyDayDeliveredOrderApi,
            formData
        );
    },
    getThirtyDayCustomer: (formData) => {
        return window.axios.post(routes.getThirtyDayCustomerApi, formData);
    },
    getLastTwelveMonthDeliveredOrderApi: (formData) => {
        return window.axios.post(
            routes.getLastTwelveMonthDeliveredOrderApi,
            formData
        );
    },
    getLastSixMonthCustomerApi: (formData) => {
        return window.axios.post(routes.getLastSixMonthCustomerApi, formData);
    },
    getWebsiteUrl: () => {
        return window.axios.get(routes.getWebsiteUrlApi);
    },
    getAndroidVendorApp: () => {
        return window.axios.get(routes.getAndroidVendorAppApi);
    },
    getAndroidDriverApp: () => {
        return window.axios.get(routes.getAndroidDriverAppApi);
    },
    getIOSVendorApp: () => {
        return window.axios.get(routes.getIOSVendorAppApi);
    },
    getIOSDriverApp: () => {
        return window.axios.get(routes.getIOSDriverAppApi);
    },
    getAndroidCustomerApp: () => {
        return window.axios.get(routes.getAndroidCustomerAppApi);
    },
    getIOSCustomerApp: () => {
        return window.axios.get(routes.getIOSCustomerAppApi);
    },
    getAndroidStaticCustomerApp: () => {
        return window.axios.get(routes.getAndroidStaticCustomerAppApi);
    },
    getIOSStaticCustomerApp: () => {
        return window.axios.get(routes.getIOSStaticCustomerAppApi);
    },

    getIOSTestFlightCustomerApp: () => {
        return window.axios.get(routes.getIOSTestFlightCustomerAppApi);
    },

    getGeneralQRCode: () => {
        return window.axios.get(routes.getGeneralQRCodeApi);
    },
};
