import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const brandservice = {
    // get Restorunt services
    getbrandrestaurant:(formData) => window.axios.post(routes.getBrandrestaurant,formData),

    getbrand: (formData) => window.axios.post(routes.getBrandApi,formData),

    addbrand:(formData)=>window.axios.post(routes.addBrandApi,formData),

    editbrand:(formData)=>window.axios.post(routes.editBrandApi,formData),

    updatebrand:(formData)=>window.axios.post(routes.updateBrandApi,formData),

    deletebrand:(formData)=>window.axios.post(routes.deleteBrandApi,formData),
}