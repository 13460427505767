import { routes } from '../config/api_routes'

export const notificationService = {

    //Send Notification
    send: (formData) => window.axios.post(routes.send,formData),

    //Get List of Notification
    getNotification: (formData) => window.axios.post(routes.getNotification,formData),

    updateBatchProgressStatus: (formData) => window.axios.post(routes.updateBatchProgressStatus,formData),
    getBatchProgress: (formData) => window.axios.post(routes.batchProgress,formData),

    CityWiseVendor: (formData) => window.axios.post(routes.CityWiseVendorApi,formData)
}