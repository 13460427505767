<script>
import HorizontalTopbar from "../components/horizontal-topbar";
import HorizontalNav from "../components/horizontal-nav";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";

/**
 * Horizontal-layout
 */
export default {
  components: {
    HorizontalTopbar,
    HorizontalNav,
    Footer,
    RightBar
  },
  data() {
    return {};
  },
  created: () => {
    if(document.body.setAttribute("data-layout", "horizontal") && document.body.setAttribute("data-topbar", "dark"))
    {
        document.body.removeAttribute("data-topbar", "light");
        document.body.removeAttribute("data-topbar", "colored");
        document.body.removeAttribute("data-sidebar");
    }
    else if(document.body.setAttribute("data-layout", "horizontal") && document.body.setAttribute("data-topbar", "light"))
    {
        document.body.removeAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-topbar", "colored");
        document.body.removeAttribute("data-sidebar");
    }
    else if(document.body.setAttribute("data-layout", "horizontal") && document.body.setAttribute("data-topbar", "colored"))
    {
        document.body.removeAttribute("data-topbar", "light");
        document.body.removeAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-sidebar");
    }
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>

<template>
  <div layout="horizontal">
    <!-- Begin page -->
    <div id="layout-wrapper">
      <HorizontalTopbar />
      <HorizontalNav v-if ="this.$route.name!='table-view'"/>
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content" :class="(this.$route.name == 'table-view' ? 'content-pos' :'')">
        <div class="page-content" :class="(this.$route.name == 'table-view' ? 'page--pos' :'')">
          <!-- :style="[!$auth.partner_login ? {'margin-top': '110px !important'} : {'margin-top': '#70px'}]" -->
          <div :class="(this.$route.name == 'table-view' ? 'container-pos' :'container-fluid')">
            <slot />
          </div>
          <!-- container-fluid -->
        </div>
        <!-- End Page-content -->
        <Footer />
      </div>
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
    <RightBar />
    </div>
</template>

<style scoped>
  .container-pos {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  .page--pos {
    margin-top: 0 !important;
  }
</style>
