import config from '../config'

export default {
    config: config,
}

const base = config.base_folder;

export const saasmenuItems = [
    {
        id: 1,
        label: "Clients",
        icon: "bx-user",
        link: base+"saas-client",
    }
];
