import { routes } from '../config/api_routes'
import config from '../config/index'

export const callCenterDashboardService = {

    getAllRestaurantName: (formData) => window.axios.get(routes.getCallCenterRestaurant,{params: formData}),

    checkMobileNumber: (formData) => window.axios.post(routes.checkCustomerNumberApi,formData),

    getCustomerAddress: (formData) => window.axios.post(routes.getCustomerAddressApi,formData),

    addCustomerAddress: (formData) => window.axios.post(routes.addCustomerAddressApi,formData),

    placeOrderApi: (formData) => window.axios.post(routes.placeOrderApi,formData),

    checkEmail: (data)   =>  window.axios.post(routes.checkEmailApi,data),

    userCreate: (data)   =>  window.axios.post(routes.userCreateApi,data),
    userUpdate: (data)   =>  window.axios.post(routes.userUpdateApi,data),

    placeOrderPos: (formData) => window.axios.post(routes.placeOrderPosApi,formData),
    getCallCenterDistanceDeliverychargestypeCharges: (formData) => window.axios.get(routes.getCallCenterDistanceDeliverychargestypeChargesApi,{params: formData}),

    getCallCenterDistanceDeliverychargestypeCharges: (formData) => window.axios.get(routes.getCallCenterDistanceDeliverychargestypeChargesApi,{params: formData}),

    // admin_login: (data)     =>  window.axios.post(routes.adminLoginApi,$data)
    
}