import { routes } from '../config/api_routes'

export const mailService = {

    //Get Mail
    getMail: (formData) => window.axios.post(routes.getMailApi,formData),

    //Add Mail
    addMail: (formData) => window.axios.post(routes.addMailApi,formData),

    //Get Mail By id
    getMailById: (formData) => window.axios.post(routes.getMailByIdApi,formData),

    //Update Email
    updateMailById: (formData) => window.axios.post(routes.updateMailByIdApi,formData),

    //View Mail
    viewMail: (formData) => window.axios.post(routes.viewMailApi,formData)
}