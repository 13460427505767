<script>
import config from "../../config";
import { commonService, vendorService } from "../../services";
import { clientService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import { required, email, sameAs, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import firebase from "firebase";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Progress from "./progress.vue";

export default {
  components: { Multiselect, clientService, Progress, VueGoogleAutocomplete },
  data() {
    return {
      showProgress: false,
      config: config,
      isLoading: false,
      isContactVerify: false,
      error: "",
      typeform: {
        app_name: "",
        vendor_name: "",
        vendor_number: "",
        email: "",
        password: "",
        confirm_password: "",
        country_code: "",
        timezone: "UTC",
        currency: "&#36;",
        currency_short_name: "USD",
        website_url: "",
        is_quick_signup: true,
        product_type_id:""
      },
      showOtp:false,
      verifyOtpLoader:false,
      currencies: [],
      currency: [],
      timezones: [],
      timezone: [],
      countrycodes: [],
      login: [],
      countries: [],
      logo: "",
      frontUrl: "",
      default_appname: "",
      termscondition: "",
      termscondition_error: false,
      isSubmitDisable: false,
      product_type_list: [],
      business_type_list: [],
    };
  },
  validations: {
    typeform: {
      app_name: {
        required,
      },
      vendor_name: {
        required,
      },
      vendor_number: {
        required,
        numeric,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
      country_code: {
        required,
      },
      // timezone: {
      //     required,
      // },
      // currency: {
      //     required,
      // },
      product_type_id: {
          required,
      },
      website_url: {},
    },
  },

  mounted() {
    let data = localStorage.getItem("quick_signup_vendor");
    data = JSON.parse(data);
    if (data) {
      this.showProgress = true;
    }

    this.getProductBusinessTypes();
    // this.getLanguage();
    // this.initFirebase();
    // $(window).load(function(){
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    // });
    this.getLanguage();
  },
  methods: {
    userCheckEmail(e) {
      commonService
        .checkVendorEmail({
          email: this.typeform.email,
          table: "Vendor",
          column: "vendor_id",
          vendor_role: "2",
        })
        .then((response) => {
          if (response.data.code === 101) {
            this.isSubmitDisable = true;
            error_message(response.data.message);
            this.typeform.email = "";
            this.isSubmitDisable = false;
          }
        });
    },
    userCheckPhone(e) {
      if (this.typeform.vendor_number != "") {
        commonService
          .checkVendorNumber({
            vendor_number: this.typeform.vendor_number,
            table: "Vendor",
            column: "vendor_id",
            vendor_role: "2",
          })
          .then((response) => {
            if (response.data.code === 101) {
              this.isSubmitDisable = true;
              error_message(response.data.message);
              this.typeform.vendor_number = "";
              this.isSubmitDisable = false;
            }
          });
      }
    },
    checkClientNumber(mobile_number) {
      commonService
        .checkClientNumber({ mobile_number: mobile_number })
        .then((response) => {
          if (response.data.code === 200) {
            this.addClient();
          } else {
            error_message(response.data.message);
          }
        });
    },
    getProductBusinessTypes() {
      clientService.getProductBusinessTypes().then((response) => {
        if (response.data.result.product_type_list) {
          this.product_type_list = response.data.result.product_type_list;
        }
        if (response.data.result.business_type_list) {
          this.business_type_list = response.data.result.business_type_list;
        }
      });
      this.getLanguage();
    },
    nameWithLang({ code, name }) {
      return `(+${code}) ${name}`;
    },
    asyncFind(query) {
      vendorService.getLanguage(query).then((response) => {
        this.countrycodes = response.data.result.country_code;
      });
    },
    clearAll() {
      this.countries = [];
    },
    nameWithCurrency({ symbol, name }) {
      return `${symbol} (${name})`;
    },
    asyncCurrencyFind(query) {
      vendorService.getLanguage(query).then((response) => {
        this.currencies = response.data.result.currency;
      });
    },
    clearAll() {
      this.currency = [];
    },
    asyncZoneFind(query) {
      vendorService.getLanguage(query).then((response) => {
        this.timezones = response.data.result.timezone;
      });
    },
    clearAll() {
      this.timezone = [];
    },
    getLanguage() {
      vendorService.getLanguage().then((response) => {
        this.currencies = response.data.result.currency;
        this.timezones = response.data.result.timezone;
        this.countrycodes = response.data.result.country_code;
        if (
          response.data.result.logo &&
          response.data.result.logo != "" &&
          response.data.result.logo != null &&
          response.data.result.flat_icon_path &&
          response.data.result.flat_icon_path != "" &&
          response.data.result.flat_icon_path != null
        ) {
          this.logo =
            response.data.result.flat_icon_path +
            "/" +
            response.data.result.logo;
          // document.querySelector('[type="image/x-icon"]').setAttribute('href', this.logo);
        }
        if (
          response.data.result.app_name &&
          response.data.result.app_name != ""
        ) {
          this.default_appname = response.data.result.app_name;
          // document.querySelector('head title').textContent = response.data.result.app_name;
        }
      });
    },
    termsconditionfun(term) {
      this.termscondition_error = false;
      if (term != "accepted") {
        this.termscondition_error = true;
      }
    },
    addClient() {
      this.termscondition_error = false;
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        if (this.termscondition != "accepted") {
          this.termscondition_error = true;
        }
        return;
      } else {
        if (this.termscondition != "accepted") {
          this.termscondition_error = true;
          return;
        }
        this.isSubmitDisable = true;
        commonService
          .quickSignup({
            ...this.typeform,
            country_code: "+" + this.typeform.country_code.code,
            country_code_id: this.typeform.country_code.id,
            currency: this.typeform.currency.symbol,
            product_type_id: this.typeform.product_type_id,
            business_type_id:4,
            timezone: this.typeform.timezone
              .replace(/[(](.*?)[)]\s/, "")
              .replace(", ", "/"),
          })
          .then((response) => {
            if (response.data.code === 200) {
              this.login = {
                email: this.typeform.email,
                password: this.typeform.password,
                login_type: "vendor",
                vendor_id: response.data.result.vendor_id ?? null,
              };

              localStorage.setItem(
                "quick_signup_vendor",
                JSON.stringify(this.login)
              );
              // success_message(response.data.message);
              this.typeform = {
                vendor_name: "",
                vendor_number: "",
                email: "",
                password: "",
                confirm_password: "",
                country_code: "",
                currency: "",
                currency_short_name: "",
                website_url: "",
              };
              this.frontUrl = "";
              this.termscondition = "";
              this.$v.typeform.$reset();
              //redirect to login page

              // this.$auth.login(this.login, (type) => {
              //     this.isSubmitDisable = false;
              //     document.querySelector('title').innerHTML= this.$auth.setting.app_name;
              //     let fvicon = document.querySelector('[type="image/x-icon"]');
              //     if (fvicon) {
              //         fvicon.setAttribute('href',this.$auth.setting.flat_icon);
              //     }
              //     this.$router.push({ name: type });
              // });

              // this.$router.push({ name: 'account-setup' });
              this.showProgress = true;
              // this.$router.push({ name: "dashboard" });
            } else {
              error_message(response.data.message);
              this.isSubmitDisable = false;
            }
          });
      }
    },
    onCurrencyChange() {
      if (this.typeform.currency) {
        var curr = this.currencies.find(
          (elem) => elem.symbol == this.typeform.currency.symbol
        );
        if (curr) {
          this.typeform.currency_short_name =
            curr.short_name != null ? curr.short_name : "";
        }
      } else {
        this.typeform.currency_short_name = "";
      }
    },
    removeSpecialChars(str) {
      return str
        .replace(/(?![A-Za-z])./g, "")
        .replace(/\s+/g, " ")
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2");
    },
    updateFrontLink(e) {
      this.typeform.website_url = "";
      this.frontUrl = "";
      if (e.target.value && e.target.value != "") {
        this.typeform.website_url = this.removeSpecialChars(
          e.target.value
        ).toLowerCase();
        this.frontUrl = (
          this.typeform.website_url != ""
            ? "https://" + this.typeform.website_url + (this.typeform.product_type_id  == 2 ? config.ordeResstoWebDefaultUrl: config.orderWebDefaultUrl)
            : ""
        ).toLowerCase();
      }
    },
    emailOtpSend(){
      // this.showOtp = true;

      // setTimeout(() => this.otpJs(), 1000);
      this.termscondition_error = false;
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        if (this.termscondition != "accepted") {
          this.termscondition_error = true;
        }
        return;
      } else {
        if (this.termscondition != "accepted") {
          this.termscondition_error = true;
          return;
        }
        this.isSubmitDisable = true;
        commonService
          .quickSignupEmailOtpSend({
            email: this.typeform.email,
            vendor_name: this.typeform.vendor_name,
          })
          .then((response) => {
            if (response.data.code === 200) {
              success_message(response.data.message);
              this.showOtp = true;

              setTimeout(() => this.otpJs(), 1000);

            } else {
              error_message(response.data.message);
              this.isSubmitDisable = false;
            }
          });
      }

    },

    otpJs(){
      window.$(".digit-group").find("input").each(function () {
          window.$(this).attr("maxlength", 1);
          window.$(this).on("keyup", function (e) {
              var parent = window.$(window.$(this).parent());
              if (e.keyCode === 8 || e.keyCode === 37) {
                  var prev = parent.find("input#" + window.jQuery(this).data("previous"));
                  if (prev.length) {
                      window.jQuery(prev).select();
                  }
              } else if (((e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 49 && e.keyCode <= 57) ||  e.keyCode === 39 || e.keyCode == 229) ) {
                  var next = parent.find("input#" + window.jQuery(this).data("next"));
                  if (next.length) {
                      window.jQuery(next).select();
                  } else {
                      if (parent.data("autosubmit")) {
                          parent.submit();
                      }
                  }
              }
              if(e.keyCode === 9){
                  var prev = parent.find("input#" + window.jQuery(this).data("previous"));
                  if (prev.length) {
                      window.jQuery(prev).select();
                  }
              }
          });
      });
    },
    resendCode(){
      commonService.quickSignupEmailOtpReSend({
        email: this.typeform.email,
        vendor_name: this.typeform.vendor_name,
      }).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);

          } else {
            error_message(response.data.message);
            this.isSubmitDisable = false;
          }
      });
    },

    getCode() {
        var code1 = document.getElementById("digit-1").value;
        var code2 = document.getElementById("digit-2").value;
        var code3 = document.getElementById("digit-3").value;
        var code4 = document.getElementById("digit-4").value;
        var code5 = document.getElementById("digit-5").value;
        var code6 = document.getElementById("digit-6").value;
        return (code1 + "" + code2 + "" + code3 + "" + code4 + "" + code5 + "" + code6);
    },
    verifyOTP(){
      var code = this.getCode();
      if(code.length == 6){
        this.verifyOtpLoader = true;
        commonService.quickSignupEmailOtpVerify({otp: code,email: this.typeform.email}).then((response) => {
            if (response.data.code === 200) {
              success_message(response.data.message);
              this.verifyOtpLoader = false;
              this.showOtp = false;
              this.addClient();
            } else {
              error_message(response.data.message);
              this.verifyOtpLoader = false;
            }
        });
      }
    },
    handlePaste(event){
      const pastedValue = event.clipboardData.getData('text')
      if (pastedValue.length > 1 && /^\d+$/.test(pastedValue)) {
        const otpCode = pastedValue.slice(0, 6);
        $('#digit-1').val(otpCode.slice(0,1))
        $('#digit-2').val(otpCode.slice(1,2))
        $('#digit-3').val(otpCode.slice(2,3))
        $('#digit-4').val(otpCode.slice(3,4))
        $('#digit-5').val(otpCode.slice(4,5))
        $('#digit-6').val(otpCode.slice(5,6))
        
        // console.log(otpCode.slice(0,1))
        // console.log(otpCode.slice(1,2))
        // console.log(otpCode.slice(2,3))
        // console.log(otpCode.slice(3,4))
        // console.log(otpCode.slice(4,5))
        // console.log(otpCode.slice(5,6))


      }
    }
  },
};
</script>

<template>
  <div>
    <div class="loader" v-if="isLoading"></div>
    <div class="account-pages my-5 pt-5" v-else>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-7 login-main">
            <div class="card overflow-hidden">
              <div class="bg-soft-primary">
                <div class="row">
                  <div class="col-12">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">
                        {{ trans.get("__JSON__.Sign up for") }}
                        {{ default_appname }} !
                      </h5>
                      <p>
                        {{
                          trans.get(
                            "__JSON__.Fill all the details to setup account with"
                          )
                        }}
                        {{ default_appname }}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="col-5 align-self-end">
                                        <img :src="config.basepath+'profile-img.png'" alt class="img-fluid" />
                                    </div> -->
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <div class="avatar-md profile-user-wid mb-4 avatar-logo-main">
                    <span class="avatar-title rounded-circle bg-light">
                      <img :src="logo" alt />
                    </span>
                  </div>
                </div>
                <div class="row d-none">
                  <h4 class="card-title pl-3 ml-1">
                    {{ trans.get("__JSON__.Client Info") }}
                  </h4>
                </div>
                <!-- <b-form class="p-2 mt-4" @submit.prevent="addClient"> -->
                <b-form class="p-2 mt-4" @submit.prevent="emailOtpSend">
                  <slot />
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="control-label">{{
                          trans.trans("__JSON__.Product You are Interested In")
                        }}</label>
                        <select
                          class="custom-select"
                          v-model="$v.typeform.product_type_id.$model"
                          id="product_type_id"
                          name="product_type_id"
                          :class="{ 'is-invalid': $v.typeform.product_type_id.$error }"
                          >
                          <!-- @change="onProductChange($event)" -->
                          <option value="" selected>
                            {{ trans.get("__JSON__.Select Product You are Interested In") }}
                          </option>
                          <option
                            v-for="product in product_type_list"
                            :value="product.id"
                            :key="product.id"
                          >
                            {{ product.name }} {{ product.extratext }}
                          </option>
                        </select>
                        <div
                          v-if="$v.typeform.product_type_id.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.product_type_id.required">{{
                            trans.trans("__JSON__.Please select at least one product.")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    :class="{
                      businessbm: typeform.website_url != '' && frontUrl != '',
                    }"
                  >
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Restaurant Name')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          name="app_name"
                          ref="app_name"
                          v-model="$v.typeform.app_name.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="
                            this.trans.get('__JSON__.Enter Restaurant Name')
                          "
                          :class="{ 'is-invalid': $v.typeform.app_name.$error }"
                          v-on:keyup="updateFrontLink"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.app_name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.app_name.required">{{
                            trans.get("__JSON__.Please enter restaurant name")
                          }}</span>
                        </div>
                      </b-form-group>
                      <span v-if="typeform.website_url != '' && frontUrl != ''">
                        <a :href="frontUrl" target="_blank">{{
                          typeform.website_url }}{{ typeform.product_type_id  == 2 ? config.ordeResstoWebDefaultUrl:config.orderWebDefaultUrl }} </a>
                      </span>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Your Name')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          name="vendor_name"
                          ref="vendor_name"
                          v-model="$v.typeform.vendor_name.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="
                            this.trans.get('__JSON__.Enter Your Name')
                          "
                          :class="{
                            'is-invalid': $v.typeform.vendor_name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.vendor_name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.vendor_name.required">{{
                            trans.get("__JSON__.Please enter your name")
                          }}</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="this.trans.get('__JSON__.Email')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          name="email"
                          ref="email"
                          @blur="userCheckEmail"
                          v-model="$v.typeform.email.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="this.trans.get('__JSON__.Enter email')"
                          :class="{ 'is-invalid': $v.typeform.email.$error }"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.email.required">{{
                            trans.get("__JSON__.Please enter email")
                          }}</span>
                          <span v-if="!$v.typeform.email.email">{{
                            trans.get(
                              "__JSON__.This value should be a valid email"
                            )
                          }}</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label required" for="ajax">{{
                          this.trans.get("__JSON__.Country")
                        }}</label>
                        <multiselect
                          v-model="$v.typeform.country_code.$model"
                          :options="countrycodes"
                          :custom-label="nameWithLang"
                          :placeholder="
                            trans.get('__JSON__.Select Country Code')
                          "
                          label="name"
                          track-by="name"
                          selectLabel=""
                          :class="{
                            'is-invalid': $v.typeform.country_code.$error,
                          }"
                        >
                          <!-- @search-change="asyncFind" -->
                          <template>
                            <span slot="noResult"
                              >{{ trans.get("No Country Code Found") }}.</span
                            >
                          </template>
                        </multiselect>
                        <div
                          v-if="$v.typeform.country_code.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.country_code.required">{{
                            trans.get("__JSON__.Please select country")
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        :label="trans.get('__JSON__.Phone Number')"
                        label-for="input-1"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-1"
                          maxlength="15"
                          minlength="10"
                          name="vendor_number"
                          ref="vendor_number"
                          @blur="userCheckPhone"
                          v-model="$v.typeform.vendor_number.$model"
                          type="text"
                          autocomplete="off"
                          :placeholder="trans.get('__JSON__.Phone Number')"
                          :class="{
                            'is-invalid': $v.typeform.vendor_number.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.vendor_number.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.vendor_number.required">{{
                            trans.get("__JSON__.Please enter phone number")
                          }}</span>
                          <span v-if="!$v.typeform.vendor_number.numeric">{{
                            trans.get(
                              "__JSON__.This value should be a valid number"
                            )
                          }}</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <!-- <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="control-label required" id="ajax">{{ trans.get('__JSON__.Currency') }}</label>
                                                <multiselect 
                                                    v-model="$v.typeform.currency.$model" 
                                                    :options="currencies" 
                                                    :placeholder="trans.get('__JSON__.Select Currency')"
                                                    label="name" 
                                                    track-by="name"
                                                    selectLabel=''
                                                    deselectLabel=''
                                                    @input="onCurrencyChange"
                                                    :class="{ 'is-invalid': $v.typeform.currency.$error }"
                                                >
                                                    <template slot="option" slot-scope="props">
                                                        <div class="option__desc"><span class="option__title" v-html="props.option.symbol"></span><span class="option__small">  ({{ props.option.name }}) </span></div>
                                                    </template>
                                                    <template>
                                                        <span slot="noResult">{{ trans.get('No Currency Found') }}.</span>
                                                    </template>
                                                </multiselect>  
                                                <div v-if="$v.typeform.currency.$error" class="invalid-feedback">
                                                    <span v-if="!$v.typeform.currency.required">{{ trans.get('__JSON__.Please select currency') }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="control-label required" id="ajax">{{ trans.get('__JSON__.Timezone') }}</label>
                                                <multiselect 
                                                    v-model="$v.typeform.timezone.$model" 
                                                    :options="Object.values(timezones)" 
                                                    :placeholder="trans.get('__JSON__.Select Time Zone')"
                                                    selectLabel=''
                                                    :class="{ 'is-invalid': $v.typeform.timezone.$error }"
                                                >
                                                    <template>
                                                        <span slot="noResult">{{ trans.get('No Time Zone Found') }}.</span>
                                                    </template>
                                                </multiselect>  
                                                <div v-if="$v.typeform.timezone.$error" class="invalid-feedback">
                                                    <span v-if="!$v.typeform.timezone.required">{{ trans.get('__JSON__.Please select timezone') }}</span>  
                                                </div>
                                            </div>    
                                        </div>
                                    </div> -->

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="this.trans.get('__JSON__.Password')"
                        label-for="input-2"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="$v.typeform.password.$model"
                          name="password"
                          type="password"
                          autocomplete="off"
                          :placeholder="
                            this.trans.get('__JSON__.Enter password')
                          "
                          :class="{ 'is-invalid': $v.typeform.password.$error }"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.password.required">{{
                            trans.get("__JSON__.Please enter password")
                          }}</span>
                        </div>
                      </b-form-group>
                    </div>

                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        :label="this.trans.get('__JSON__.Confirm Password')"
                        label-for="input-2"
                        label-class="required"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="$v.typeform.confirm_password.$model"
                          name="confirm_password"
                          type="password"
                          autocomplete="off"
                          :placeholder="
                            this.trans.get('__JSON__.Re-enter password')
                          "
                          :class="{
                            'is-invalid': $v.typeform.confirm_password.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.confirm_password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.confirm_password.required">{{
                            trans.get("__JSON__.Please re enter your password")
                          }}</span>
                          <span
                            v-else-if="
                              !$v.typeform.confirm_password.sameAsPassword
                            "
                            >{{
                              trans.get("__JSON__.Passwords must match")
                            }}</span
                          >
                        </div>
                      </b-form-group>
                    </div>

                  </div>

                  <div>
                    <b-form-checkbox
                      id="terms"
                      v-model="termscondition"
                      name="terms"
                      value="accepted"
                      unchecked-value=""
                      :class="{ 'is-invalid': termscondition_error }"
                      @change="termsconditionfun"
                    >
                      {{ trans.get("__JSON__.I Agree") }}
                      <a
                        href="https://deonde.co/terms-of-service.shtml"
                        target="_blank"
                        >{{ trans.get("__JSON__.Terms of Service") }}</a
                      >
                      &
                      <a
                        href="https://deonde.co/privacy-policy.shtml"
                        target="_blank"
                        >{{ trans.get("__JSON__.Privacy policy") }}</a
                      >
                      {{ trans.get("__JSON__.of") }} {{ default_appname }}.
                    </b-form-checkbox>
                    <div v-if="termscondition_error" class="invalid-feedback">
                      <span v-if="termscondition_error">{{
                        trans.get("__JSON__.Please select the checkbox")
                      }}</span>
                    </div>
                  </div>

                  <div>
                    <button
                      id="submit"
                      class="btn btn-primary btn-block mt-5"
                      @keyup.enter="emailOtpSend"
                      :disabled="isSubmitDisable"
                    >
                      {{ trans.get("__JSON__.Signup") }}
                    </button>
                  </div>
                </b-form>

                <!-- otp modal -->
                <!-- ref="emailOtpModal" -->
                 <div v-if="showOtp">
                   <b-modal
                    v-model="showOtp"
                     title="Email verification"
                     hide-footer
                     hide-header-close
                     no-close-on-backdrop
                   >

                   <div class="row customerotp" v-if="showOtp">
                      <div class="col-md-12 text-center">
                          <div class="otp-boxs position-relative">
                              <h4 class="mb-2 mt-2">Enter OTP</h4>
                              <form method="get" class="digit-group" data-group-name="digits" data-autosubmit="false" autocomplete="off">
                                  <input @paste="handlePaste($event)" type="text" id="digit-1" name="digit-1" data-next="digit-2" maxlength="1" autofocus="autofocus" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
                                  <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');"  />
                                  <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                                  <!-- <span class="splitter">&ndash;</span> -->
                                  <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                                  <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                                  <input type="text" id="digit-6" name="digit-6" data-previous="digit-5" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                                </form>
                                <!-- {{(countDown > 0)? "00:"+("0" + countDown).slice(-2):''}} -->
                                <div id="recaptchacontainer"></div>
                                <div class="col-md-12 text-right resend mt-2">
                                  <button class="btn " style="margin-right: 55px;" @click="resendCode()">Resend</button>
                                </div>
                          </div>
                      </div>
                      <div class="col-md-12 mt-2">
                          <button type="button" class="btn btn-primary btn-block" @click="verifyOTP()" :disabled="verifyOtpLoader">
                              <b-spinner v-if="verifyOtpLoader" class="align-middle"></b-spinner>
                              Verify & Submit
                          </button>
                      </div>
                  </div>
                   </b-modal>
                 </div>




              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
    <Progress v-if="showProgress" />
  </div>
</template>
<style type="text/css">
.businessbm {
  margin-bottom: 1rem;
}
.businessbm .form-group {
  margin-bottom: 0px;
}
.spinner-border {
  height: 20px !important;
  width: 20px !important;
}
.digit-group input:focus{
  box-shadow: 0px 3px 4px -1px #2b4ae0;
}
.digit-group input {
  /* width: 40px;
  height: 50px;
  border: 1px solid grey;
  text-align: center;
  margin: 0 2px;
  outline: none;
  border-radius: 5px;
  font-size: 22px; */

  width: 45px;
  height: 45px;
  border: 1px solid #e0e0e0;
  text-align: center;
  margin: 0 2px;
  outline: none;
  border-radius: 6px;
  font-size: 22px;
  /* box-shadow: 0px 3px 4px -1px; */
  box-shadow: 0px 1px 3px -1px;
}
.customerotp {
  margin: 0 auto;
}
.loader {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.loader:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #2e2e2e transparent #2e2e2e transparent;
  animation: loader 0.7s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>