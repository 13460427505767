import { routes } from "../config/api_routes";

export const customerService = {
    getCustomer: (formData) =>
        window.axios.post(routes.getCustomerApi, formData),

    customerExportCsv: (formData) =>
        window.axios.post(routes.customerExportToCsvApi, formData),

    dayWiseCustomerExportCsv: (formData) =>
        window.axios.post(routes.dayWiseCustomerExportCsvApi, formData),

    noOrderDayCustomerExportCsv: (formData) =>
        window.axios.post(routes.noOrderDayCustomerExportCsvApi, formData),

    withWalletBalanceCustomerExportCsv: (formData) =>
        window.axios.post(
            routes.withWalletBalanceCustomerExportCsvApi,
            formData
        ),

    withMaxOrdersCustomerExportCsv: (formData) =>
        window.axios.post(routes.withMaxOrdersCustomerExportCsvApi, formData),

    withCancelOrderCustomerExportCsv: (formData) =>
        window.axios.post(routes.withCancelOrderCustomerExportCsvApi, formData),

    getCustomerProfile: (formData) =>
        window.axios.post(routes.getCustomerProfileApi, formData),

    updateCustomerProfile: (formData) =>
        window.axios.post(routes.updateCustomerProfileApi, formData),
    addCustomer: (formData) =>
        window.axios.post(routes.addCustomerApi, formData),

    getCustomerOrder: (formData) =>
        window.axios.post(routes.getCustomerOrderApi, formData),

    changeCustPassword: (formData) =>
        window.axios.post(routes.changeCusPasswordApi, formData),

    getDayWiseCustomer: (formData) =>
        window.axios.post(routes.getDayWiseCustomerApi, formData),

    getNoOrderDayCustomer: (formData) =>
        window.axios.post(routes.getNoOrderDayCustomerApi, formData),

    getWithWalletBalanceCustomer: (formData) =>
        window.axios.post(routes.getWithWalletBalanceCustomerApi, formData),

    getWithMaxOrdersCustomer: (formData) =>
        window.axios.post(routes.getWithMaxOrdersCustomerApi, formData),

    getCancelOrderCustomer: (formData) =>
        window.axios.post(routes.getCancelOrderCustomerApi, formData),

    getOsWiseCustomer: (formData) =>
        window.axios.post(routes.getOsWiseCostomerApi, formData),

    withOsWiseCustomerExportCsv: (formData) =>
        window.axios.post(routes.withOsWiseCustomerExportCsvApi, formData),

    getAverageOrderCustomer: (formData) =>
        window.axios.post(routes.getAverageOrderCustomerApi, formData),

    getAverageCustomerExportCsv: (formData) =>
        window.axios.post(routes.getAverageOrderCustomerExportCsvApi, formData),

    getWithPromoCodeUsageCustomer: (formData) =>
        window.axios.post(routes.getWithPromoCodeUsageCustomerApi, formData),

    getWithPromoCodeUsageCustomerExportCsv: (formData) =>
        window.axios.post(
            routes.getWithPromoCodeUsageCustomerExportApi,
            formData
        ),

    getPostedRestaurantNegativeReviewCustomer: (formData) =>
        window.axios.post(
            routes.getPostedRestaurantNegativeReviewCustomerApi,
            formData
        ),

    getPostedDriverNegativeReviewCustomer: (formData) =>
        window.axios.post(
            routes.getPostedDriverNegativeReviewCustomerApi,
            formData
        ),

    getPostedRestaurantNegativeReviewCustomerExportCsv: (formData) =>
        window.axios.post(
            routes.getPostedRestaurantNegativeReviewCustomerExportApi,
            formData
        ),

    getPostedDriverNegativeReviewCustomerExportCsv: (formData) =>
        window.axios.post(
            routes.getPostedDriverNegativeReviewCustomerExportApi,
            formData
        ),

    getProCustomer: (formData) =>
        window.axios.post(routes.getProCustomersApi, formData),

    getProCustomerExportCsv: (formData) =>
        window.axios.post(routes.getProCustomersExportApi, formData),

    getCustomerDashboardCount: (formData) =>
        window.axios.post(routes.getCustomerDashboardCountApi, formData),
};
