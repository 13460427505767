import config from "../config";

export default {
    config: config,
};

const base = config.base_folder;

export const resellerMenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: base + "dashboard",
    },
    {
        id: 2,
        label: "Clients",
        icon: "bx-user",
        link: base + "client",
    }
];
