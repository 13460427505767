import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const RestaurantTableService = {
    // get Restorunt services
    //getwaitingrestaurant:(formData) => window.axios.post(routes.getWaitingrestaurant,formData),

    get_table_list: (formData) => window.axios.post(routes.getTableApi,formData),

    add_table:(formData)=>window.axios.post(routes.addTableApi,formData),

    edit_table:(formData)=>window.axios.post(routes.editTableApi,formData),

    update_table:(formData)=>window.axios.post(routes.updateTableApi,formData),

    update_table_status:(formData)=>window.axios.post(routes.updateTableStatusApi,formData),

    deleteTable:(formData)=>window.axios.post(routes.deleteTableApi,formData),
}