

<template>
     <vertical>
      <div>
       <div class="row header-new">
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between icon-main-page"
            >
              <div class="page-title-left">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                    <i class="bx bx-help-circle"></i>
                  </span>
                </div>
                <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.What’s New !') }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="b-card-whats-new">
                  <div class="whats-new-title" v-for="update in updates" :key="update.id">
                    <div v-html="update.description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vertical>
</template>



<script>
import Vertical from "../../layouts/vertical";
import PageHeader from "../../components/page-header";
import { dashboardService } from "../../services";
/**
 * Nav-bar Component
 */
export default {
  components: { Vertical, PageHeader, dashboardService },
  data() {
    return{
        updates: []
    };
  },
  mounted(){
      this.getUpdate();
  },
  methods: {
    getUpdate() {
        dashboardService.getUpdate().then((response) => {
            this.updates = response.data.result;
        })
    },
  },
};
</script>
