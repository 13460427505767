
// Banner services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const chatService = {
    // get banner 
    // info: sortDesc : Boolean

    getUserListing: (formData) => window.axios.post(routes.getUserListing,formData),   
    getUserMessage: (formData) => window.axios.post(routes.getUserMessage,formData), 
    sendMessage: (formData) =>  window.axios.post(routes.sendMessage,formData), 
    getUnreadMessage: (formData) => window.axios.post(routes.getUnreadMessageApi,formData),
    setMarkAsRead: (formData) => window.axios.post(routes.setMarkAsReadApi, formData),
    getVendorListing: (formData) => window.axios.post(routes.getVendorListing,formData),
    getVendorMessage: (formData) => window.axios.post(routes.getVendorMessage,formData), 
    setVendorMarkAsRead: (formData) => window.axios.post(routes.setVendorMarkAsRead,formData),
    getDriverListing: (formData) => window.axios.post(routes.getDriverListing,formData),
    getDriverMessage: (formData) => window.axios.post(routes.getDriverMessage,formData), 
    setDriverMarkAsRead: (formData) => window.axios.post(routes.setDriverMarkAsRead,formData)
  
}